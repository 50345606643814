// For Active Storage
// import * as ActiveStorage from "@rails/activestorage"
// ActiveStorage.start();

// note: action cable not setup since I didn't know how and I don't use it

import '@hotwired/turbo-rails';
import 'bootstrap';
import '../vendor/dashkit/fonts/feather/feather.css';
import 'chartkick/chart.js';
import ClipboardJS from 'clipboard';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import Picker from 'vanilla-picker';
import {railsConfig} from './constants.js.erb';
import 'alpine-turbo-drive-adapter';
import Alpine from 'alpinejs';
window.Alpine = Alpine;
import $ from 'jquery';
window.$ = $;

document.addEventListener('turbo:load', (event) => {
  window.$ = $;
  Alpine.start(); // must be after window.$ = $; so that alpine can use jquery

  // Bootstrap
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  if (typeof gtag === 'function') {
    gtag('config', railsConfig.ga4TrackingId, {
      'page_location': event.detail.url,
    });
  }

  if (ClipboardJS.isSupported()) {
    const clipboard = new ClipboardJS('.clipboardjs');
    document.addEventListener('turbo:before-cache', function destroyClipboard(event) {
      event.currentTarget.removeEventListener(event.type, destroyClipboard);
      clipboard.destroy();
    });
  } else {
    document.querySelectorAll('.clipboardjs').forEach(function(element) {
      element.style.display = 'none';
    });
  }

  if (document.querySelector("[data-behavior='flatpickr']")) {
    const fp = flatpickr("[data-behavior='flatpickr']", {
      altInput: true,
      altFormat: 'F j, Y',
      dateFormat: 'Y-m-d',
    });
    document.addEventListener('turbo:before-cache', function destroyFlatpickr(event) {
      event.currentTarget.removeEventListener(event.type, destroyFlatpickr);
      fp.forEach(function(pickr) {
        const input = pickr.input;
        const value = input.value;
        pickr.destroy();
        input.value = value;
        return pickr;
      });
    });
  }

  const colorPickerElems = document.getElementsByClassName('color-picker');
  for (const colorPickerElem of colorPickerElems) {
    const colorField = colorPickerElem.querySelector('input[type=color]');
    let picker = new Picker({
      parent: colorPickerElem,
      color: colorField.value,
      alpha: false,
      popup: 'bottom',
      onDone: function(color) {
        colorField.value = color.hex.slice(0, 7); // slice removes transparency
      },
    });
    document.addEventListener('turbo:before-cache', function destroyVanillaPicker(event) {
      event.currentTarget.removeEventListener(event.type, destroyVanillaPicker);
      if (picker !== null) {
        picker.destroy();
        picker = null;
      }
    });
  }
});

document.addEventListener('turbo:before-cache', function() {
  (function destroyProloEmbed() {
    // TODO: the Prolo object should be able to destroy itself
    // TODO: the prolo.js run idempotently
    /**
     * This removes the elements that the prolofinder embedded map creates when it loads.
     * THERE IS NO TESTS TO PROVE THIS since I could not reproduce it in tests.
     * There are two issues
     * 1) Navigate to map, navigate to new page, press back
     * 2) Navigate to map, navigate to new page, navigate to map
     */
    function removeElementById(id) {
      const elem = document.getElementById(id);
      return elem.parentNode.removeChild(elem);
    }
    const proloAssetIds = ['ProloLogic', 'ProloCSS', 'ProloMappingCSS'];
    for (let i = 0; i < proloAssetIds.length; i++) {
      const id = proloAssetIds[i];
      if (document.getElementById(id) !== null) {removeElementById(id);}
    }
    const proloFinderElement = document.getElementById('ProloFinder');
    if (proloFinderElement) {
      proloFinderElement.innerHTML = '';
    }
  })();
});

document.addEventListener('turbo:before-cache', function() {
  if ('prolofinderDestroy' in window) {
    prolofinderDestroy();
  }
});
